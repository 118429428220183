<template>
    <v-app>
      <v-card class="bgs rounded-0 d-flex justify-center" flat width="100%" height="100%">
        <v-container>
            <h2 class="text-center mt-6 font24 mb-4">
          {{ $t("Taoify 使用协议") }}
        </h2>
        <h3 class="titlee">{{ $t("定义") }}</h3>
<p class="content">
  {{ $t("“通过 Taoify 注册和使用的用户”以下简称甲方。") }}
</p>
<p class="content">
  {{ $t("“Taoify”以下简称乙方。") }}
</p>

<h3 class="titlee">{{ $t("协议目的") }}</h3>
<p class="content">
  {{ $t("甲方愿意购买乙方 Taoify 平台产品和服务。为明确双方责任与义务，根据相关法规，经协商，甲、乙双方就甲方使用乙方“Taoify平台”达成如下协议并承诺共同遵守。") }}
</p>

<h3 class="titlee">{{ $t("第一条 服务项目") }}</h3>
<p class="content">
  {{ $t("甲方自愿使用乙方 Taoify 平台，并接受相关技术及网络支持服务。该网站只限于在乙方所使用的专门提供 Taoify 平台服务的服务器上运行，不支持转移到其它非 Taoify 平台服务器或非乙方服务器上运行。甲方理解并同意，乙方所提供的 Taoify 平台系统中所包含的网站模板均仅供展示布局效果，且该些网站模板均由其他第三方设计师所提供、著作权归该模板的设计师所有，但设计师昵称为“Taoify官方”的模板是由乙方提供、著作权归该乙方所有；若甲方要使用相应网站模板的应当另行购买，并根据甲方网站的特点和内容，自行更换模板中所包含的一切元素（例如，图片、图示、标题、文字等）。") }}
</p>

<h3 class="titlee">{{ $t("第二条 双方的权利和义务") }}</h3>
<h4 class="sub-titlee">{{ $t("2-1 甲方的权利和义务") }}</h4>
<ol class="content">
  <li>{{ $t("甲方可以自行管理其网站，通过其网站的管理及设计功能修改网页风格和相关文字内容，可以利用网站在国际互联网上发布信息，并可以自行决定信息的内容。") }}</li>
  <li>{{ $t("Taoify 平台存在几款不同产品类型供甲方选择，甲方可根据需要选用其中任一款。甲方理解并同意，由于不同用户可能选用同一款产品类型，因此页面风格存在雷同属正常现象。") }}</li>
  <li>{{ $t("对于超出甲方购买档次的网站限定的资源的行为，乙方有权采取任何措施予以制止，包括但不限于关闭网站等，由此引起的一切后果由甲方自行负责，同时，甲方已经支付的费用将不予退还。") }}</li>
  <li>{{ $t("甲方承诺不进行下列行为：") }}
    <ol>
      <li>{{ $t("通过散布大量不受欢迎的或者未经请求的电子邮件、电子广告等为该网站进行宣传、介绍或者招揽业务；") }}</li>
      <li>{{ $t("建立或利用有关设备、配置运行超出网站应用范围的行为、程序、进程或软件等，导致大量占用服务器内存、CPU资源或者网络带宽资源，给乙方或者乙方的其他甲方的网络、网站或者服务器（包括但不限于本地及外地和国际的网络、网站或者服务器等）带来严重的负荷，影响乙方与国际互联网或者乙方与特定网络、服务器及乙方内部的通畅联系，或者导致乙方服务器或者乙方的其他用户网站所在的服务器宕机、死机等；") }}</li>
      <li>{{ $t("进行任何破坏或试图破坏网络安全的行为；") }}</li>
      <li>{{ $t("进行任何改变或试图改变乙方提供的系统配置或破坏系统安全的行为；") }}</li>
      <li>{{ $t("运行影响网站服务器或者乙方服务器正常工作的程序、进程等；") }}</li>
      <li>{{ $t("其他超出乙方服务范围，可能给乙方带来任何不利影响的行为或者是国家禁止的行为。") }}</li>
    </ol>
  </li>
  <li>{{ $t("甲方必须遵守国家的相关法律、法规或规定，包括但不限于《计算机信息网络国际联网安全保护管理办法》、《中华人民共和国计算机信息网络国际联网管理暂行规定》等。甲方不得制作、复制、发布、传播任何法律法规禁止的有害信息。甲方对其经营行为和发布的信息违反上述规定而引起的任何政治责任、法律责任和给乙方造成的经济损失承担全部责任。甲方不得利用乙方提供的资源和服务上传（Upload）、下载（Download）、储存、发布违法信息。甲方同时承诺不得为他人发布不符合国家规定和/或本合同的信息内容提供任何便利。甲方承认乙方有权根据乙方自己谨慎的判断来决定甲方发布的内容是否构成违反本合同或者国家的有关规定。甲方承诺在接到乙方的通知后会调整甲方网站上的内容以符合国家规定或者本合同约定，否则乙方有权终止本合同下的服务。乙方发现甲方或者甲方的客户从事上述活动的，乙方有权随时终止服务；因甲方从事上述活动给乙方带来任何损失或者不利影响的，甲方应承担赔偿责任；因甲方的客户从事上述活动而给乙方带来任何损失或不利影响的，甲方应与其客户承担连带赔偿责任。") }}</li>
  <li>{{ $t("甲方对进行或违反2-1-4和/或2-1-5的行为所产生的后果并对所发布信息的内容所产生的影响、后果对乙方或有关第三方承担全部责任。甲方同意，发生上述事件，与乙方没有任何关系，乙方也不应对此或对第三方承担任何责任或者义务。") }}</li>
  <li>{{ $t("甲方对自己在网站上发布的信息、内容，以及管理网站的口令、密码的完整性和保密性负责。因甲方维护不当或保密不当致使上述信息、内容、口令、密码等丢失或泄漏所引起的一切损失和后果均由甲方自行承担。") }}</li>
  <li>{{ $t("甲方应向乙方提交甲方执行本合同的联系人和管理甲方网站的人员名单和联系方式并提供必要的协助，因甲方人员的行为或者不作为而产生的后果均由甲方承担。") }}</li>
  <li>{{ $t("甲方承认乙方向甲方提供的任何资料、技术或技术支持、软件、服务等的知识产权均属于乙方所有，甲方无权复制、传播、转让、许可或提供他人使用这些资源，否则应承担相应的责任。") }}</li>
  <li>{{ $t("如果甲方利用乙方提供的服务进行的经营活动需要获得国家有关部门的认可或批准的，甲方应获得与之有关的认可或批准。如因甲方未及时办理该等手续导致的一切后果均由甲方自行承担责任；甲方还应承担因此给乙方造成的一切损失（包括但不限于信息产业部等有关部门的罚金等）。同时甲方理解乙方须根据国家法律法规和通信管理部门的规定和通知中断、终止为甲方提供本协议项下的全部或部分服务并不退还任何已交付款项。") }}</li>
  <li>{{ $t("甲方明确，甲方所购买的网站申请成功后并不意味着可以立即使用。甲方还需进行以下操作： 在 Taoify 平台开通之后，及时登录乙方会员专区，对网站进行基础配置。") }}</li>
  <li>{{ $t("甲方购买 Taoify 平台的服务，可以购买多个服务，每个服务期限自购买之日起计算，服务期间不可暂停。") }}</li>
</ol>

<h4 class="sub-titlee">{{ $t("2-2 乙方的权利和义务") }}</h4>
<ol class="content">
  <li>{{ $t("乙方保证甲方网站的正常稳定运行，并提供相关的网络和技术支持。") }}</li>
  <li>{{ $t("本合同中的网站只支持在乙方所使用的专门提供 Taoify 平台服务的服务器上运行，不支持甲方将网站转移到非 Taoify 平台服务器或非乙方提供的服务器上运行。") }}</li>
  <li>{{ $t("乙方为甲方提供相关帮助文档和咨询服务，帮助甲方充分地发挥网站的作用。") }}</li>
  <li>{{ $t("保留因甲方违反2-1-3、2-1-4、2-1-5、2-1-6、2-1-8、2-1-9和/或2-1-10而终止甲方网站运行的权利。同时乙方对甲方进行或违反2-1-4和/或2-1-5的行为所产生的后果及对甲方所发布信息的内容所产生的影响、后果不承担责任。") }}</li>
  <li>{{ $t("消除甲方非人为操作所出现的故障，但因甲方原因和/或不可抗力以及非乙方控制范围之内的事项除外。") }}</li>
  <li>{{ $t("收取有关费用以及按照规定应收取的其他费用。") }}</li>
  <li>{{ $t("乙方保证网站的兼容性、易用性和安全性，主动进行官方模板和网站后台的改进和升级。") }}</li>
  <li>{{ $t("乙方有权在必要时修改本合同服务条款，乙方服务条款一旦发生变动，将会在有关页面上提示修改内容。甲方如果不同意所改动的内容，可以主动取消获得的服务；如果甲方继续享用服务，则视为接受服务条款的变动。") }}</li>
</ol>

<h3 class="titlee">{{ $t("第三条 费用和期限") }}</h3>
<p class="content">
  {{ $t("注：①有效期自甲方购买乙方产品之日起计算。") }}
</p>
<ol class="content">
  <li>{{ $t("甲方完全理解乙方价格体系中所有的赠送服务项目均为乙方在正常服务价格之外的一次性特别优惠，优惠内容不包括赠送服务项目的修改、更新及维护费用，并且赠送服务项目不可折价冲抵服务价格。对于赠送的服务项目，乙方拥有最终的解释权。") }}</li>
  <li>{{ $t("关于续费问题：如果甲方在产品到期前没有及时续费，服务即告终。则乙方将有权关闭甲方的网站。关闭帐号之日起10日后，若甲方依然没有续费，乙方将有权删除甲方网站的全部内容，由此带来的一切损失由甲方自行负责。") }}</li>
  <li>{{ $t("关于佣金问题：如果甲方实际交易额超过产品套装内的免佣额度，需支付超过部分的佣金。佣金将在交易过程中按每笔自动收取或在每个自然月结束后核算，甲方在收到账单5个工作日内完成支付。甲方必须确保在约定的时间完成支付，否则乙方有权终止甲方所购买的服务。") }}</li>
</ol>

<h3 class="titlee">{{ $t("第四条 服务终止及违约责任") }}</h3>
<ol class="content">
  <li>{{ $t("在服务有效期间，如果甲方提出终止要求，应提前一个月通知乙方，但甲方已交纳的费用不得要求返还。甲方擅自终止本合同给乙方造成损害的应承担相应的责任。") }}</li>
  <li>{{ $t("因甲方过错、违反法律或者本合同等原因，乙方有权提前终止服务。") }}</li>
  <li>{{ $t("如果甲方违反其义务或者在本合同下的保证/承诺，乙方有权关闭甲方的网站，由此造成的损失由甲方负责。") }}</li>
  <li>{{ $t("如果在服务期间或期满之后甲方需要乙方的其他服务，双方另行协商。") }}</li>
  <li>{{ $t("乙方在进行服务器配置、维护时需要短时间中断服务，或者由于Internet上的通路的阻塞造成甲方网站访问速度下降，甲方均认为是正常情况，不属于乙方责任。鉴于计算机、互联网的特殊性，因黑客问题、电信部门技术调整和政府管制等引起的事件，也不属于乙方违约，乙方不应承担责任。") }}</li>
  <li>{{ $t("如果因乙方原因造成网站不能正常访问，乙方以天为单位向甲方赔偿损失。如果乙方及其提供给甲方的网站连续72小时不能提供正常服务，甲方可以终止接受服务并可以要求赔偿损失，但非乙方控制之内的原因引起的除外。赔偿总额以该款网站的年服务费用的总额为上限。") }}</li>
  <li>{{ $t("如果因甲方原因，甲方的网站给所在服务器带来异常大的负荷，以致影响该网站所在服务器的正常运行或者影响其他甲方的正常使用（包括但不限于2-1-4所述的大量占用乙方服务器内存、CPU资源的行为），乙方有权关闭甲方的网站，终止服务且余款不予退还。") }}</li>
  <li>{{ $t("乙方对因第三方的过错或者延误而给甲方或者其他第三方造成的损失不负责任。") }}</li>
  <li>{{ $t("乙方对通过甲方间接接受乙方服务的第三方的损失不负责任。") }}</li>
  <li>{{ $t("如果因甲方使用的第三方服务造成网站故障或第三方服务无法正常使用，乙方不承担任何责任。") }}</li>
</ol>

<h3 class="titlee">{{ $t("第五条 争议解决") }}</h3>
<ol class="content">
  <li>{{ $t("与本 Taoify 平台服务有关的一切争议，双方当事人应通过友好协商方式解决。") }}</li>
  <li>{{ $t("如果协商未成，双方同意向乙方主要经营地的人民法院起诉。") }}</li>
</ol>

<h3 class="titlee">{{ $t("第六条 不可抗力") }}</h3>
<ol class="content">
  <li>{{ $t("因不可抗力或者其他意外事件，使得本合同的履行不可能、不必要或者无意义的，遭受不可抗力、意外事件的一方不承担责任。") }}</li>
  <li>{{ $t("不可抗力、意外事件是指不能预见、不能克服并不能避免且对一方或双方当事人造成重大影响的客观事件，包括但不限于自然灾害如洪水、地震、瘟疫流行等以及社会事件如战争、动乱、政府行为、电信主干线路中断等。") }}</li>
</ol>

<h3 class="titlee">{{ $t("第七条 其他约定") }}</h3>
<ol class="content">
  <li>{{ $t("有关条款的无效不影响本合同的解释、违约责任及争议解决的有关约定的效力。") }}</li>
  <li>{{ $t("本合同的有关条款或者约定若与双方以前签署的有关条款或与以前的有关陈述不一致或相抵触的，以本合同为准；但经双方协商一致同意保留的除外。") }}</li>
  <li>{{ $t("任何一方对另一方当事人的商业秘密（包括但不限于经营和技术秘密、源代码、数据库等）均负有保密的义务。") }}</li>
  <li>{{ $t("一方变更通知、通讯地址或其他联系方式，应自变更之日起一个月内，将变更后的地址、联系方式通知另一方，否则变更方应对此造成的一切后果承担责任。") }}</li>
  <li>{{ $t("因乙方上市、被收购、与第三方合并、名称变更等事由，甲方同意乙方可以将其权利和/或义务转让给相应的乙方权利/义务的承受者。") }}</li>
  <li>{{ $t("乙方在相关页面上对于 Taoify 平台的服务说明、价格说明、申请表格等是本合同不可分割的一部分，与本合同具有同等法律效力。") }}</li>
  <li>{{ $t("本协议为网络版，与实际签订的 Taoify 合同一致，具有同等法律效力。") }}</li>
</ol>

          <p class="content">
            © Taoify 2024 
          </p>
        </v-container>
      </v-card>
    </v-app>
  </template>
  
  <script>
  export default {};
  </script>
  
  <style lang="scss" scoped>
  .titlee {
    font-size: 18px;
    font-weight: 600;
    color: #333;
    line-height: 25px;
    margin-top: 25px;
    margin-bottom: 10px;
  }
  .content {
    font-size: 16px;
    line-height: 30px;
    font-weight: 300;
    color: #6a6e79;
  }
  li {
    font-size: 16px;
    line-height: 30px;
    font-weight: 300;
    color: #6a6e79;
    margin-left: 20px;
    list-style: initial;
  }
  </style>